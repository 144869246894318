<template>
    <main>
        <page-header-compact>REGISTRO DE PADRE</page-header-compact>
         <div class="container-xl px-4" >
            <div class="card card-icon mt-4">
                <div class="row no-gutters">
                    <div class="col-auto card-icon-aside bg-gray-200">
                        <i class="fas fa-user fa-fw text-dark"></i>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <div class="form-row">
                                <label class="small p-0 col-form-label col-2">Nro. Identificación (*)</label>
                                <div class="col-10">
                                    <usuario-field v-model="usuario" :validator="$v.usuario"></usuario-field>
                                    <span class="text-danger" v-if="!$v.usuario.required && $v.usuario.$dirty">
                                        Este campo es requerido
                                    </span>
                                </div>
                            </div>
                            <div class="form-row mt-2 mb-0">
                                <table class="table table-sm mb-0">
                                    <tr>
                                        <td><strong>F. Nacimiento :</strong> {{usuario.fecha_nacimiento | formatDate}}</td>
                                        <td><strong>Sexo :</strong> {{usuario.sexo === 'M' ? 'MASCULINO' : 'FEMENINO' }}</td>
                                        <td><strong>Edad :</strong> {{usuario.years}}</td>
                                        <td><strong>Nivel :</strong> {{usuario.nivel}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <strong>EPS :</strong> ({{eps.IDEPS}}) {{eps.DESEPS}}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center" v-if="Object.keys(usuario).length">
                <button class="btn btn-success mt-4 btn-sm lift-sm" @click="vincularUsuario();">Vincular Padre al Usuario <i class="fa fa-link fa-fw"></i></button>
            </div>
            <div class="card mt-2" v-else>
                <div class="card-header">
                    <h5 class="card-title mb-0">Datos</h5>
                </div>
                <div class="card-body">
                    <h5 class="card-title border-bottom border-bottom-dark mt-2 pb-2">
                                <i class="fas fa-user-shield fa-fw"></i> Datos personales
                            </h5>
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label class="small mb-1">Tipo documento</label>
                                    <select
                                        v-model="usuarios.tipo_documento"
                                        :class="{
                                                'is-invalid': $v.usuarios.tipo_documento.$error
                                              }"
                                        aria-label="tercero"
                                        class="form-control form-control-sm"
                                    >
                                        <option
                                            v-for="(tipoDocumento, index) in tipoDocumentos"
                                            v-bind:key="index"
                                            :value="tipoDocumento.id"
                                        >
                                            {{ tipoDocumento.nombre }}
                                        </option>
                                    </select>
                                    <!-- <span class="invalid-feedback " v-if="!$v.usuarios.tipo_documento.required">  este campo es requerido</span> -->
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="small mb-1">
                                        Documento
                                    </label>
                                    <input
                                        v-model="usuarios.documento"
                                        :class="{ 'is-invalid': $v.usuarios.documento.$error }"
                                        class="form-control form-control-sm"
                                        type="text"
                                    />
                                    <span
                                        v-if="!$v.usuarios.documento.isUnique && $v.usuarios.documento.$dirty"
                                        class="text-danger"
                                    >
                                        El numero de documento ya se encuentra registrado
                                    </span>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label class="small mb-1" for="inputFirstName">Primer nombre</label>
                                    <input  v-model="usuarios.nombre_1"
                                        :class="{ 'is-invalid': $v.usuarios.nombre_1.$error }"
                                        class="form-control form-control-sm"
                                        required
                                        type="text"
                                    />
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="small mb-1">Segundo nombre</label>
                                    <input
                                        v-model="usuarios.nombre_2"
                                        class="form-control form-control-sm"
                                        font-size="responsive"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label class="small mb-1">Primer apellido</label>
                                    <input
                                        id="inputFirstName"
                                        v-model="usuarios.apellido_1"
                                        :class="{ 'is-invalid': $v.usuarios.apellido_1.$error }"
                                        class="form-control form-control-sm"
                                        required
                                        type="text"
                                    />
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="small mb-1">Segundo apellido</label>
                                    <input v-model="usuarios.apellido_2" class="form-control form-control-sm" type="text" />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label class="small mb-1" >Genero</label>
                                    <select
                                        v-model="usuarios.genero"
                                        :class="{ 'is-invalid': $v.usuarios.genero.$error }"
                                        class="form-control form-control-sm"
                                    >
                                        <option
                                            v-for="(genero, index) in generos"
                                            v-bind:key="index"
                                            :value="genero.nombre_guardado"
                                        >
                                            ({{ genero.nombre }})-{{ genero.nombre_guardado }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="small mb-1">Fecha de nacimiento</label>
                                    <input v-model="usuarios.fecha_nacimiento"
                                           :class="{
                                        'is-invalid': $v.usuarios.fecha_nacimiento.$error
                                    }"
                                        class="form-control form-control-sm"
                                        type="date"
                                    />
                                </div>
                            </div>
                        <h5 class="card-title border-bottom border-bottom-dark mt-2 pb-2 mt-2 pb-2">
                            <i class="fas fa-check-double fa-fw"></i> Información General
                        </h5>
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label class="small mb-1">Ocupación</label>
                                    <VSelectProfesion
                                        v-model="usuarios.id_profesion"
                                        mode="object"
                                    >
                                    </VSelectProfesion>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="small mb-1">Estado civil</label>
                                    <vSelectEstadoCivil
                                        v-model="usuarios.estado_civil"
                                        mode="object"
                                    >
                                    </vSelectEstadoCivil>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label class="small mb-1">Etnia</label>
                                    <vSelectEtnia v-model="usuarios.etnia" mode="object"></vSelectEtnia>
                                </div>
                                <div class="form-group col-md-6 input-group-sm">
                                    <label class="small mb-1">Teléfono</label>
                                    <input
                                        v-model="usuarios.telefono"
                                        :class="{ 'is-invalid': $v.usuarios.telefono.$error }"
                                        class="form-control form-control-sm"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label class="small mb-1">Nivel</label>
                                    <select  id="nivel"
                                        v-model="usuarios.nivel"
                                        class="form-control form-control-sm">
                                        <option selected value="1">Nivel 1</option>
                                        <option value="2">Nivel 2</option>
                                        <option value="3">Nivel 3</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <h5 class="card-title border-bottom border-bottom-dark mt-2 pb-2">
                            <i class="fas fa-street-view fa-fw"></i> Ubicación
                        </h5>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="small mb-1">Municipio</label>
                                <vSelectMunicipio
                                    v-model="usuarios.municipio"
                                    :class="{ 'v-select-error': $v.usuarios.municipio.$error }"
                                    mode="object"
                                >
                                </vSelectMunicipio>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="small mb-1">Dirección</label>
                                <input
                                    v-model="usuarios.direccion"
                                    :class="{ 'is-invalid': $v.usuarios.direccion.$error }"
                                    class="form-control form-control-sm"
                                    type="text"
                                />
                            </div>
                            <div class="form-group col-md-6 input-group-sm">
                                <label class="small mb-1">Zona</label>
                                <vSelectZona
                                    v-model="usuarios.zona"
                                    mode="object">
                                </vSelectZona>
                            </div>
                        </div>
                        <h5 class="card-title border-bottom border-bottom-dark mt-2 pb-2">
                            <i class="fas fa-hand-holding-medical fa-fw"></i>
                            Afiliación
                        </h5>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="small mb-1">EPS</label>
                                <v-select-eps
                                    v-model="usuarios.eps"
                                    :class="{ 'v-select-error': $v.usuarios.eps.$error }"
                                    mode="object"
                                >
                                </v-select-eps>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="small mb-1">Contrato</label>
                                <select class="form-control form-control-sm" v-model="contrato">
                                    <option v-for="(contrato) in contratos_eps" :value="contrato" :key="contrato.id">
                                        ({{contrato.codigo}}) {{contrato.descripcion}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12 d-flex justify-content-end">
                                <button class="btn btn-sm btn-primary shadow-sm" @click="agregarContrato()">
                                    <i class="fas fa-plus fa-fw"></i>
                                    Agregar
                                </button>
                            </div>
                        </div>
                        <h5 class="card-title border-bottom border-bottom-dark mt-2 pb-2">
                            <i class="fas fa-layer-group"></i>
                            Contratos Activos
                        </h5>
                        <div class="form-row">
                            <div class="col-12">
                                <ul class="list-group">
                                    <li
                                        v-for="(contrato, i) in contratos_usuario"
                                        :key="`${contrato.id}_${i}`"
                                        class="list-group-item d-flex justify-content-between align-items-center">
                                        ({{contrato.codigo}}) {{contrato.descripcion}}
                                        <button class="btn btn-transparent-dark btn-datatable btn-sm" @click="eliminarContrato(i)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
            
                    <div class="form-group">
                        <div class="col-12">
                            <button class="btn btn-success shadow-sm btn-sm lift-sm" type="button" @click="save()">Guardar datos <i class="far fa-save fa-fw"></i></button>
                        </div>
                    </div>
                </div>
            </div>
    </main>
</template>

<script>
import PageHeaderCompact from "../../components/layouts/content/page-header-compact";
import {required} from 'vuelidate/lib/validators';
import Swal from "sweetalert2";
import UsuarioField from "../../components/common/UsuarioField";
import {isEmpty} from "lodash";

import moment from "moment";
import VSelectProfesion from "../../components/common/VSelectProfesion";
import vSelectZona from "../../components/common/vSelectZona";
import vSelectEtnia from "../../components/common/vSelectEtnia";
import vSelectEstadoCivil from "../../components/common/vSelectEstadoCivil";
import vSelectMunicipio from "../../components/common/vSelectMunicipio";
import VSelectEps from "../../components/common/VSelectEps";
import usuariosEvtService from "../../services/usuariosEvtService";
export default {
    name: "create.vue",
    components: {
        VSelectProfesion,
        vSelectZona,
        vSelectEtnia,
        vSelectEstadoCivil,
        VSelectEps,
        vSelectMunicipio,UsuarioField, PageHeaderCompact
    },
    data () {
        return {
            idUsuario:'',
            usuario : {},
            eps:{},
            tipoDocumentos: [],
            generos: [],
            usuarios: {
                nombre_1: "",
                nombre_2: "",
                apellido_1: "",
                apellido_2: "",
                genero: "",
                documento: "",
                tipo_documento: "",
                fecha_nacimiento: "",
                id_profesion: {},
                estado_civil: "",
                etnia: {},
                zona: {},
                direccion: "",
                municipio: {},
                telefono: "",
                eps: {},
                nivel: "",
            },
            contrato : {},
            contratos_usuario : []
        }
    },

    filters: {
        upper: function (value) {
            return value.toUpperCase();
        },
    },
    validations: {
        usuario : {required},
        usuarios: {
            nombre_1: {required},
            apellido_1: {required},
            genero: {required},
            documento: {
                required,
                async isUnique(value) {
                    if (value === "") {
                        return true;
                    }

                    const response = await usuariosEvtService.duplicado(
                        `${value}`
                    );

                    return Boolean(response.data);
                },
            },
            tipo_documento: {required},
            fecha_nacimiento: {required},
            direccion: {required},
            municipio: {required},
            telefono: {required},
            eps: {required},
        }
    },
    methods: {
        limpiar() {
            this.usuarios.nombre_1 = "";
            this.usuarios.nombre_2 = "";
            this.usuarios.apellido_1 = "";
            this.usuarios.apellido_2 = "";
            this.usuarios.genero = "";
            this.usuarios.documento = "";
            this.usuarios.tipo_documento = "";
            this.usuarios.fecha_nacimiento = "";
            this.usuarios.estado_civil = "";
            this.usuarios.direccion = "";
            this.usuarios.nivel = "";
            this.usuarios.telefono = "";
            this.usuarios.id_profesion = {};
            this.usuarios.etnia = {};
            this.usuarios.zona = {};
            this.usuarios.municipio = {};
            this.usuarios.eps = {};
        },
        async listarTipoDocumento() {
            const response= await usuariosEvtService.tipoDocumento();
            this.tipoDocumentos = response.data;
        },
        async listarGeneros() {
           const response= await usuariosEvtService.genero();
            this.generos = response.data;
            
        },
        async vincularUsuario(){
            try {
                this.LoaderSpinnerShow();
                this.usuario['id_usuario']=this.idUsuario;
                await usuariosEvtService.update(this.usuario);
                this.LoaderSpinnerHide();
                await Swal.fire({
                    title: "Exito!",
                    text: "Acudiente vinculado con exíto",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                 this.$router.push({
                    name: 'pail.perfil.usuario', params: { id:this.id_usuario }
                });
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Swal.fire('Ups!', 'ha ocurrido un error al procesar la solicitud', 'error');
            }
        },
        async save() {
            try{
                this.$v.usuarios.$touch();

                if (this.$v.usuarios.$invalid) {
                    return;
                }

                const tipoDocSelected = this.tipoDocumentos.find(
                    (x) => x.id === this.usuarios.tipo_documento
                );

                if (!(this.edad >= tipoDocSelected.edad_inicio && this.edad <= tipoDocSelected.edad_final)) {
                    Swal.fire(
                        `El tipo documento seleccionado no cumple con el rango ${tipoDocSelected.edad_inicio} hasta ${tipoDocSelected.edad_final} años`,
                        "",
                        "error"
                    );

                    return;
                }

                this.LoaderSpinnerShow();

                await usuariosEvtService.store({
                    ...this.usuarios,
                    contratos :  this.contratos_usuario
                });

                this.LoaderSpinnerHide();

                Swal.fire({
                    title: "Exito!",
                    text: "Usuario creado con exíto",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });

                this.limpiar();


            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire('Ups!', 'ha ocurrido un error al procesar la solicitud', 'error');
            }
        },
        agregarContrato(){
            this.contratos_usuario.push({...this.contrato});
        },
        eliminarContrato(i){
            this.contratos_usuario.splice(i, 1);
        }
    },
    created() {
        this.idUsuario=this.$route.params.idUsuario;
        this.listarTipoDocumento();
        this.listarGeneros();
    },
    computed: {
        edad: function () {
            if (isEmpty(this.usuarios.fecha_nacimiento)) {
                return "";
            }

            return moment()
                .diff(moment(this.usuarios.fecha_nacimiento, "YYYY-MM-DD"), "year")
                .toString();
        },
        contratos_eps :  function(){
            if(isEmpty(this.usuarios.eps)) return [];
            return this.usuarios.eps.contratos;
        }
    },
    watch : {
        usuario : function(){
            if(isEmpty(this.usuario.contratos)){
                Swal.fire('Ups!', 'El usuario seleccionado no tiene contratos activos o configurados', 'warning');
            }else{
                this.eps = this.usuario.contratos[0].contrato.eps
            }
        }
    }
}
</script>

<style scoped>

</style>
